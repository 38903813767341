<template>
  <el-dialog
    :title="dialogTitle"
    :visible="dialogVisible"
    :width="width"
    top="6vh"
    :destroy-on-close="true"
    @open="open"
    @close="close"
  >
    <div v-if="type==='user'" class="table-list">
      <div class="left">
        <el-form ref="form" :model="form" label-width="0" label-position="right">
          <el-form-item prop="name">
            <el-input v-model="form.name" placeholder="搜索姓名/手机号" clearable @keyup.enter.native="search">
              <i slot="append" class="el-icon-search cursor" @click="search" />
            </el-input>
          </el-form-item>
        </el-form>
        <!-- @selection-change="handleSelectionChange" -->
        <!-- @select="handleSelectionChange"
          @select-all="selectAll" -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          tooltip-effect="dark"
          row-key="id"
          style="width: 100%"
          @select="handleSelectionChange"
          @select-all="selectAll"
        >
          <el-table-column
            :reserve-selection="true"
            :selectable="checkSelectable"
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column
            prop="userName"
            label="姓名"
            width="145"
            align="center"
          />
          <el-table-column
            prop="phone"
            label="联系电话"
            width="147"
            align="center"
          />
        </el-table>
        <el-pagination
          small
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.size"
          :current-page="pagination.page"
          @current-change="pageChange"
        />
      </div>
      <div class="right">
        <div>已选择{{ selectData.length }}人</div>
        <el-table
          class="select-table-wrap"
          :data="selectData"
          style="width: 100%"
          border
          max-height="486px"
        >
          <el-table-column
            prop="userName"
            label="姓名"
            :width="showDelete?133:173"
            align="center"
          />
          <el-table-column
            prop="phone"
            label="联系电话"
            :width="showDelete?133:175"
            align="center"
          />
          <el-table-column
            v-if="showDelete"
            label="操作"
            align="center"
          >
            <template slot-scope="{ row }">
              <div class="main-c cursor" @click="deleteSelectItem(row)">删除</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else class="table-list">
      <div class="left">
        <el-form ref="form" :model="form" label-width="0" label-position="right">
          <el-form-item prop="companyName">
            <el-input v-model="form.companyName" placeholder="搜索用户姓名、手机号码" clearable @keyup.enter.native="search">
              <i slot="append" class="el-icon-search cursor" @click="search" />
            </el-input>
          </el-form-item>
        </el-form>
        <!-- @selection-change="handleSelectionChange" -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          tooltip-effect="dark"
          row-key="id"
          style="width: 100%"
          @select="handleSelectionChange"
          @select-all="selectAll"
        >
          <el-table-column
            :reserve-selection="true"
            :selectable="checkSelectable"
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column
            prop="companyName"
            label="承运人"
            width="145"
            align="center"
          >
            <template slot-scope="{ row }">
              <UserInfoCard size-type="table" :need-change-user-info="false" :need-match="false" :user-info="row" :user-type="2" />
            </template>
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系电话"
            width="147"
            align="center"
          />
          <!-- 11承运商企业 12信息公司 13个体运力 -->
          <el-table-column
            prop="titleTypeDesc"
            label="业务资质类型"
            width="145"
            align="center"
          />
        </el-table>
        <el-pagination
          small
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.size"
          :current-page="pagination.page"
          @current-change="pageChange"
        />
      </div>
      <div class="right">
        <div>已选择{{ selectData.length }}个</div>
        <el-table
          class="select-table-wrap"
          :data="selectData"
          style="width: 100%"
          border
          max-height="486px"
        >
          <el-table-column
            prop="companyName"
            label="承运人"
            :width="showDelete?133:172"
            align="center"
          >
            <template slot-scope="{ row }">
              <UserInfoCard size-type="table" :need-change-user-info="false" :need-match="false" :user-info="row" :user-type="2" />
            </template>
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系电话"
            :width="showDelete?133:160"
            align="center"
          />
          <el-table-column
            prop="titleTypeDesc"
            label="业务资质类型"
            :width="showDelete?133:160"
            align="center"
          />
          <el-table-column
            v-if="showDelete"
            label="操作"
            align="center"
          >
            <template slot-scope="{ row }">
              <div class="main-c cursor" @click="deleteSelectItem(row)">删除</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </span>
    <!-- <span slot="footer">
      <el-button type="primary" @click="submit">推送</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { getSysTemPostUserList } from '@/api/systemUser';
import { transUserList } from '@/api/userManageMent';
export default {
  components: {},
  props: {
    // user =>小五文案相关 car=>车队相关
    type: {
      type: String,
      default() {
        return 'user';
      },
    },
    dialogTitle: {
      type: String,
      default() {
        return '新增推送小五';
      },
    },
    width: {
      type: String,
      default() {
        return '800px';
      },
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 需要回显得数据
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否显示删除操作
    showDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 有值回显依据Id
    matchId: {
      type: String,
      default() {
        return 'id';
      },
    },
    // 推送记录列表打开弹窗 列表记录存在的推送数据置灰不可点击
    disableIDList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {},
      tableData: [],
      selectData: [],
      initSelectData: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
    };
  },
  methods: {
    getList() {
      const paramsUser = {
        size: this.pagination.size,
        current: this.pagination.page,
        userPostDTOList: [
          {
            postCode: 'PT005',
          }],
      };
      const paramsCar = {
        current: this.pagination.page,
        size: this.pagination.size,
        isSearchRel: 1, // 是否只查客服绑定用户 1是
      };
      const params = this.type === 'user' ? paramsUser : paramsCar;
      if (this.type === 'user') {
        // getXWList  getRelevanceCustomerList
        getSysTemPostUserList(params).then(res => {
          if (res.records.length) {
            const array = [];
            res.records.forEach((e) => {
              array.push({
                userName: e.name,
                phone: e.phone,
                id: e.userId,
              });
            });
            this.tableData = array || [];
            this.pagination = {
              page: res.current,
              size: res.size,
              total: res.total,
            };
          }
        }).finally(() => {
          this.echoSelect();
        });
      } else {
        transUserList(params).then(res => {
          if (res.records.length) {
            const array = [];
            res.records.forEach((e) => {
              // if (this.type === 'user') {
              //   array.push({
              //     userName: e.friendUserName,
              //     // userId: e.friendUserId,
              //     phone: e.friendPhone,
              //     // id: e.userId,
              //     id: e.friendUserId,
              //   });
              // } else {
              array.push({
                phone: e.userPhone,
                titleTypeDesc: this.$CONSTANT.qualificationTypeMap[+e.titleType] || '-',
                ...e,
                id: e.userId,
              });
              // }
            });
            this.tableData = array || [];
            this.pagination = {
              page: res.current,
              size: res.size,
              total: res.total,
            };
          }
        }).finally(() => {
          this.echoSelect();
        });
      }
    },
    checkSelectable(row, index) {
      /**
        * row：当前行数据
        * index：当前第几位
      */
      let flag = true;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.disableIDList.includes(row.id)) {
          flag = false;
        } else {
          flag = true;
        }
      }
      return flag;
    },

    search() {
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
      // this.echoSelect();
    },
    handleSelectionChange(data, row) {
      this.selectData = data;
    },
    selectAll(data) {
      this.selectData = data;
    },
    echoSelect() {
      this.initSelectData = JSON.parse(JSON.stringify(this.selectData));
      if (this.selectData.length) {
        this.$nextTick(() => {
          this.tableData.forEach((item, index) => {
            if (this.selectData.findIndex((i) => i.id === item.id) >= 0) {
              this.$refs.multipleTable.toggleRowSelection(
                this.$refs.multipleTable.data[index],
                true,
              );
            }
          });
        });
      }
    },
    deleteSelectItem(data) {
      this.selectData = this.selectData.filter(user => user.id !== data.id);
      this.tableData.forEach(item => {
        if (data.id === item.id) {
          // 存在添加
          this.$refs.multipleTable.toggleRowSelection(item, false);
        }
      });
    },
    open() {
      this.selectData = [];
      this.selectData = JSON.parse(JSON.stringify(this.selectList));
      this.getList();
    },
    close() {
      // this.$refs.form.resetFields();
      this.form = {};
      this.selectData = [];
      this.tableData = [];
      this.pagination = {
        total: 0,
        size: 10,
        page: 1,
      };
      this.$refs.multipleTable.clearSelection;
      this.$emit('update:dialogVisible', false);
    },
    submit() {
      this.$emit('dialogSubmit', this.selectData);
      this.close();
    },
  },
};

</script>
<style lang='scss' scoped>
.table-list{
  display: flex;
  border: 1px solid $grey;
  border-radius: 8px;
  .left,.right{
    width: 50%;
    padding: 15px 15px 0 15px;
    .g-table-c{
      padding: 0;
    }
  }
  .left{
    border-right: 1px solid $grey;
    ::v-deep{
      .el-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }
    }
  }
  .right{
    padding-top: 25px;
    .select-table-wrap{
      margin-top:32px;
    }
  }
}

</style>
