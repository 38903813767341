import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
export const getDetailType = {
  res: (d) => {
    return {
      ...d,
      goodsStop: d.extMap ? d.extMap.goodsStop || '-' : '-',
      tags: d.extMap.tags || '',
    };
  },
};
const prefix = '/auv';
export default {
  // 小五找车货单列表
  orderGoodsXwList: function(params) {
    return request.post(`${prefix}/order/goods/xw/list`, pickTrue(params));
  },
  // 货单详情
  orderGoodsDetail: function(id) {
    return request.get(`${prefix}/order/goods/detail`, { id: id }, { showLoading: false }).then((res) => {
      res = getDetailType.res(res);
      return res;
    });
  },
  // 承接推送车队数量统计
  orderGoodsRecordTransCount: function(params) {
    return request.post(`${prefix}/order/goods/record/trans/count`, params).then((res) => {
      return res;
    });
  },
  // 推送设置详情
  orderGoodsXwPushDetail: function(id) {
    return request.get(`${prefix}/order/goods/xw/push/detail`, { id: id }, { showLoading: false }).then((res) => {
      return res;
    });
  },
  // 推送承运商
  orderGoodsPushTrans: function(inputForm) {
    return request.post(`${prefix}/order/goods/push/trans`, inputForm, { showLoading: true });
  },
  // 承运商推送记录
  orderGoodsRecordTransList: function(data) {
    return request.post(`${prefix}/order/goods/record/trans/list`, data);
  },
  // 承运商推送userId记录
  orderGoodsRecordTransUserList: function(data) {
    return request.post(`${prefix}/order/goods/record/trans/user/list`, data);
  },
  // 货源找车——待提交、待确认列表
  orderCarXwList: function(params) {
    return request.post(`${prefix}/order/car/xw/list`, params);
  },
  // 小五取消改派列表
  carXwChangeList: function(params) {
    return request.post(`${prefix}/order/car/xw/change/list`, params);
  },
  // 货主确认车单列表
  orderCarConfirmXwList: function(params) {
    return request.post(`${prefix}/order/car/confirm/xw/list`, params);
  },
};
