<template>
  <el-dialog
    title="信息重复"
    :visible="dialogVisible"
    width="600px"
    top="20vh"
    :destroy-on-close="true"
    :append-to-body="true"
    @open="open"
    @close="close"
  >
    <div>
      {{ repeatInfoText }}信息存在重复。若信息重复可能影响您的运输任务，是否确认提交？
    </div>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    originRepeatInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      repeatInfoText: '',
    };
  },
  methods: {
    uniqueFields(obj) {
      const valuesSet = new Set(); // 创建一个Set用于存储唯一值
      // 遍历对象中的所有值
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const entry = obj[key];
          for (const field in entry) {
            // eslint-disable-next-line no-prototype-builtins
            if (entry.hasOwnProperty(field)) {
              const value = entry[field];
              // 对于每个字段的值，添加到Set中，自动处理重复的情况
              valuesSet.add(value);
            }
          }
        }
      }
      // 将Set转换为数组
      return Array.from(valuesSet);
    },
    open() {
      const repeatInfo = JSON.parse(JSON.stringify(this.originRepeatInfo));
      this.repeatInfoText = this.uniqueFields(repeatInfo).join(' ');
    },
    close() {
      this.$emit('update:dialogVisible', false);
    },
    submit() {
      this.$emit('dialogSubmit');
      this.close();
    },
  },
};

</script>
<style lang='scss' scoped>
.table-list{
  display: flex;
  border: 1px solid $grey;
  border-radius: 8px;
  .left,.right{
    width: 50%;
    padding: 15px 15px 0 15px;
    .g-table-c{
      padding: 0;
    }
  }
  .left{
    border-right: 1px solid $grey;
    ::v-deep{
      .el-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }
    }
  }
  .right{
    padding-top: 25px;
    .select-table-wrap{
      margin-top:32px;
    }
  }
}

</style>
